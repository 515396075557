import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { Banner, mapToPageHeader } from "@components/Banner"
import { SEO, mapToSEO } from "@components/SEO"
import {
  SignupBannerWrapper,
  mapToKiwiVIPVisible,
} from "@components/SignupBanner"

import { FaqList, mapToFaqContainer } from "@components/FaqList"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import theme from "@styles/theme"
import { ContactUsBlock } from "@components/ContactUsBlock/index"
import { mapToContactUsBlock } from "@mappers/Contact Us/ContactUs.mapper"
import KpButton from "@elements/KpButton/KpButton"
import { Grid } from "semantic-ui-react"

const ContactUs = (props: any) => {
  const { elements } = props.data.kontentItemContactUsPage
  const apiUrl = props.data.site.siteMetadata.apiUrl
  const seo = mapToSEO(elements)
  const kiwiVip = mapToKiwiVIPVisible(elements)
  const metadata = mapToPageHeader(elements)
  const contactUsBlockData = mapToContactUsBlock(elements)
  contactUsBlockData.apiUrl = apiUrl
  const faqs = mapToFaqContainer(elements.faqs.linked_items[0].elements)
  contactUsBlockData.salesforceWebToCaseRetUrl = props.data.site.siteMetadata.siteUrl + "/contact-us/"
  contactUsBlockData.salesforceWebToCaseOrgid = props.data.site.siteMetadata.salesforceWebToCaseOrgid
  contactUsBlockData.salesforceWebToCaseLink = props.data.site.siteMetadata.salesforceWebToCaseLink
  contactUsBlockData.salesforceWebToCaseSubtypeName = props.data.site.siteMetadata.salesforceWebToCaseSubtypeName
  contactUsBlockData.reCaptchaSiteKeyV2 = props.data.site.siteMetadata.reCaptchaSiteKeyV2
  contactUsBlockData.reCaptchaSiteKey = props.data.site.siteMetadata.reCaptchaSiteKey

  return (
    <Layout location={props.location}>
      <SEO {...seo} />
      <Banner {...metadata} isBiggerBanner={false} />

      <GenericBannerContainer
        padding={{
          mobile: {
            top: 60,
            bottom: 80,
          },
          desktop: {
            top: 80,
            bottom: 100,
          },
        }}
        backgroundColor={theme.brand.colors.beige}
      >
        <ContactUsBlock {...contactUsBlockData}></ContactUsBlock>
      </GenericBannerContainer>
      <GenericBannerContainer padding={{mobile: {top: 50,bottom: 60},desktop: {top: 90,bottom: 100}}} backgroundColor={faqs.backgroundColor}>
      <FaqList
        borderColor="#212121"
        questionColor="#212121"
        answerColor="#212121"
        {...faqs}
      />
      </GenericBannerContainer>
      <GenericBannerContainer
        padding={{
          mobile: {
            top: 0,
            bottom: 80,
          },
          desktop: {
            top: 0,
            bottom: 100,
          },
        }}
        backgroundColor={theme.brand.colors.white}
      >
        <Grid>
          <Grid.Column width={16} textAlign="center">
            <KpButton
              buttonType="primary"
              link="/faqs/"
              id="ViewFaqs"
              color={theme.brand.colors.blue}
            >
              View All FAQ's
            </KpButton>
          </Grid.Column>
        </Grid>
      </GenericBannerContainer>
      {kiwiVip.visible && <SignupBannerWrapper version={kiwiVip.version} />}
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        apiUrl
        siteUrl
        salesforceWebToCaseOrgid
        salesforceWebToCaseLink
        salesforceWebToCaseSubtypeName
        reCaptchaSiteKeyV2
        reCaptchaSiteKey
      }
    }
    kontentItemContactUsPage {
      elements {
        seo__noindex {
          value {
            name
            codename
          }
        }
        seo__nofollow {
          value {
            name
            codename
          }
        }
        seo__page_title {
          value
        }
        seo__page_description {
          value
        }
        general_page_content__title {
          value
        }
        general_page_content__header_image {
          value {
            url
            description
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            name
            codename
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            name
            codename
          }
        }
        general_page_content__title_position {
          value {
            codename
          }
        }
        url {
          value
        }
        reasons_for_contact_options {
          linked_items {
            ... on KontentItemContactUsReason {
              id
              elements {
                email_subject {
                  value
                }
                order_number_combination_required {
                  value {
                    codename
                    name
                  }
                }
                reason {
                  value
                }
                subtype {
                  linked_items {
                    ... on KontentItemContactUsSubtype {
                      elements {
                        title {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        email_address {
          value
        }
        phone_number {
          value
        }
        physical_address {
          value
        }
        postal_address {
          value
        }
        send_us_a_message_top_banner {
          value
        }
        faqs {
          linked_items {
            ... on KontentItemFaqBlock {
              elements {
                title {
                  value
                }
                background_colors {
                  value {
                    codename
                  }
                }
                faqs {
                  linked_items {
                    ... on KontentItemFaqItem {
                      elements {
                        question {
                          value
                        }
                        answer {
                          value
                        }
                        faq_category {
                          value {
                            name
                            codename
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default ContactUs
